$(document).ready(function () {
    //ANIMATON PHONE & MAIL ICON
    $('.phone').on('mouseover click', function () {
        $(".icon-phone").addClass("tada");
        setTimeout(function () {
            $(".icon-phone").removeClass("tada");
        }, 1100);
    });

    $('.email').on('mouseover click', function () {
        $(".icon-mail").addClass("bounceOutUp");
        $(".icon-mail").fadeOut(2000);
        setTimeout(function () {
            $(".icon-mail").removeClass("bounceOutUp");
            $(".icon-mail").fadeIn(1000);
        }, 2000);
    });

    //BURGER MENU
    $(".burger").on("click", function (e) {
        console.log('Here here test very test')
        e.preventDefault();
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
        } else {
            $(this).addClass("open");
        }
    });

    // SCROLL NAV
    window.onscroll = function() {myFunction()};
    var header = document.getElementById("header");
    var sticky = header.offsetTop;
    function myFunction() {
        if (window.pageYOffset > sticky) {
            header.classList.add("sticky");
        } else {
            header.classList.remove("sticky");
        }
    }

    // MatchHeight page
    $('.first-row-md').matchHeight();
    $('.actu-height').matchHeight();
    $('.same-height').matchHeight();
    $('.same-height-btn').matchHeight();
    $('.same-height-footer').matchHeight();
    $('.article-height').matchHeight();
    $('.info-fonction').matchHeight();
    $('.img-news').matchHeight();
    $('.row2').matchHeight();
    $('.row1').matchHeight();

    
    $(".btn-grp a").on("click", function (e) {
        e.preventDefault();
        $(".btn-grp a").removeClass("active");
        $(this).addClass("active");
    });


    $('.js-checkbox-region input:checkbox:not(:checked).others').click(function () {
        $('.js-checkbox-region input:checkbox:not(.others)').attr('checked', false);
    });

    $('.js-checkbox-price input:checkbox:not(:checked).others').click(function () {
        $('.js-checkbox-price input:checkbox:not(.others)').attr('checked', false);
    });

    // Slider
    $('.bxslider').slick({
        dots: true,
        autoplay: true,
        arrows: false
    });

    // Custome tooltip
    $(".link-tooltip").hover(
        function () {
            var elem = $(this);
            var id_elem = elem.attr('id');
            var num = id_elem.split('-');

            $('.tooltip-container').hide();
            $('.tooltip-infos-' + num[1]).fadeIn('fast');
        },
        function () {
            var elem = $(this);
            var id_elem = elem.attr('id');
            var num = id_elem.split('-');

            $('.tooltip-infos-' + num[1]).fadeOut('fast');
        }
    );

    $('.content-conseil .bx-wrapper .bx-pager, .bx-wrapper .bx-controls-auto').css('bottom', parseInt($('.content-conseil .slider').height() - 30) + 'px');


    $('#product-viewer-mini-thumbnail-1').click(function () {
        $img = $('#product-viewer-mini-thumbnail-1').data("large-img-url");
        $title = $('#product-viewer-mini-thumbnail-1').data("title");
        $('#product-viewer-thumbnail').attr("src", $img);
        $('#product-viewer-thumbnail').attr("alt", $title);
        $('#product-viewer-thumbnail').attr("title", $title);
    });

    $('#product-viewer-mini-thumbnail-2').click(function () {
        $img = $('#product-viewer-mini-thumbnail-2').data("large-img-url");
        $title = $('#product-viewer-mini-thumbnail-2').data("title");
        $('#product-viewer-thumbnail').attr("src", $img);
        $('#product-viewer-thumbnail').attr("alt", $title);
        $('#product-viewer-thumbnail').attr("title", $title);
    });

    $('#product-viewer-mini-thumbnail-3').click(function () {
        $img = $('#product-viewer-mini-thumbnail-3').data("large-img-url");
        $title = $('#product-viewer-mini-thumbnail-3').data("title");
        $('#product-viewer-thumbnail').attr("src", $img);
        $('#product-viewer-thumbnail').attr("alt", $title);
        $('#product-viewer-thumbnail').attr("title", $title);
    });

    $('#product-viewer-mini-thumbnail-4').click(function () {
        $img = $('#product-viewer-mini-thumbnail-4').data("large-img-url");
        $title = $('#product-viewer-mini-thumbnail-4').data("title");
        $('#product-viewer-thumbnail').attr("src", $img);
        $('#product-viewer-thumbnail').attr("alt", $title);
        $('#product-viewer-thumbnail').attr("title", $title);
    });


    if(document.querySelector('.page-template-template-weare')) {
        console.log('Glide We Are')

        new Glide('.glide', {
			type: 'carousel',
			startAt: 0,
			perView: 1,
			animationTimingFunc: 'ease-in-out',
            animationDuration: 800,
            autoplay: 5000,
            gap: 50,
            hoverpause: true
		}).mount()
    }
});